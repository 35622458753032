<template>
<div class="basic">
    <v-snackbar v-model="snackbar" :timeout="6000" color="error">
      {{ errmsg }}
    </v-snackbar>

    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-row class="lighten-5 align-center justify-center">
            <v-card
              :key="1"
              class="card1 ma-2 pa-3"
              elevation=0
              flat
              width=325
              color="transparent">
              <v-row dense>
                <v-col cols="12" align="center">
                  <v-img :width="80" src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/main_pic.png" class="ma-0 pa-0" />
                </v-col>
              </v-row>
              <v-row dense class="subtitle-2">
                이름(별명)
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    @change="check_name"
                    label="이름(별명)"
                    required
                    :error-messages="err_name"
                    :hint="hint_name"
                    background-color="transparent" />
                </v-col>
                <v-col>
                  <v-radio-group v-model="sex" row>
                    <v-radio label="남" value="M"></v-radio>
                    <v-radio label="여" value="F"></v-radio>
                  </v-radio-group>
                </v-col>

              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="height"
                    label="키"
                    required
                    background-color="transparent" />
                </v-col>
              </v-row>
              <v-row dense class="subtitle-2">
                약관동의
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text class="pa-2">
                      <v-row dense>
                        <v-col cols="2">
                          <v-checkbox v-model="agree_all" />
                        </v-col>
                        <v-col cols="6">
                          전체동의
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="pa-2">
                      <v-row dense>
                        <v-col cols="2">
                          <v-checkbox v-model="contract" />
                        </v-col>
                        <v-col cols="8">
                          이용 약관
                        </v-col>
                        <v-col cols="2" class="caption">
                          (필수)
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="pa-2">
                      <v-row dense>
                        <v-col cols="2">
                          <v-checkbox v-model="handle_private" />
                        </v-col>
                        <v-col cols="8">
                          개인정보취급방침
                        </v-col>
                        <v-col cols="2" class="caption">
                          (필수)
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="pa-2">
                      <v-row dense>
                        <v-col cols="2">
                          <v-checkbox v-model="event_sms" />
                        </v-col>
                        <v-col cols="8">
                          이벤트, 프로모션 알림 메일 및 SMS 수신
                        </v-col>
                        <v-col cols="2" class="caption">
                          (선택)
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="#FBDE44FF"
                    large
                    class="elevation-0"
                    @click="join"
                    :disabled="validation()"
                    block
                  >
                  확인
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

export default {
  data: () => {
    return {
      height: 170,
      agree_all: false,
      contract: false,
      handle_private: false,
      event_sms: false,
      snackbar: false,
      errmsg: "",
      valid_name: false,
      name: "",
      err_name: "",
      hint_name: "",
      sex: 'M',
      id_token: 'id_token',
    }
  },
  watch: {
    agree_all : async function(v, ov) {
      if ( v == true ) {
        this.contract = true;
        this.handle_private = true;
        this.event_sms = true;
      } else {
        this.contract = false;
        this.handle_private = false;
        this.event_sms = false;
      }
    }

  },
  methods: {
    check_name: async function(v) {
      const is_username = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/name', {
        params: { name: v }
      });

      if ( is_username.data > 0 ) {
        this.err_name = "이미 같은 이름(별명)이 사용중입니다.";
        this.valid_name = false;
      } else {
        this.err_name = "";
        this.hint_name = "사용할 수 있습니다.";
        this.valid_name = true;
      }

    },
    validation: function() {
      if ( this.contract == true
        && this.handle_private == true
        && this.valid_name == true) {
        return false;
      } else
        return true
    },
    join: async function(ev) {
      ev.preventDefault();

      const user = firebase.auth().currentUser;
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/agreement', {
        name: this.name,
        uid: user.uid,
        email: user.email,
        sex: this.sex,
        dietstatus: 0,
        agreement_use: this.contract,
        agreement_private: this.handle_private,
        agreement_event: this.event_sms,
        height: this.height,
      }, {
        headers: {'id_token': id_token},
      });

      this.$store.dispatch("fetchSex", this.sex);

      this.$router.push({ path:"/main" });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
